import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import ThinLayout from '@/components/ThinLayout';
import Link from '@/components/commons/link';
import routes from '@/services/routes';
import Panel from '@/components/commons/panel';
import Header from '@/components/commons/panel/Header';
import SignInWithGoogle from '@/components/branding/SignInWithGoogle';
import ExclamationMarkSolid from '@/components/commons/icons/ExclamationMarkSolid';
import useURL from '@/hooks/use_url';

import styles from '@/components/login/Login.module.css';
import onboardingStyles from '@/components/onboarding/Onboarding.module.css';
import MagicLinkForm from '@/components/accounts/MagicLinkForm';
import SignInWithMsft from '@/components/branding/SignInWithMsft';

export const IN_PROD = process.env.NEXT_PUBLIC_ENV === 'prod';

function Login() {
    const useMagicLink = useURL().use_magic_link;
    const breakerTripped = useURL().breaker_tripped as string;
    const accountDeleted = useURL().deleted !== undefined;
    const missingCredentials = useURL().missing_credentials;
    const [hasSeenBreakerError, setHasSeenBreakerError] = useState(false);

    // find the target out of the url
    const { target } = useURL();
    // try to build the frontend redirect URL with the target
    // if we can't find a target, assume we're going to root
    const targetPath = routes.FRONTEND_URL(target || routes.ROOT);
    // set up the oauth URL and if we don't have a target, set a login param
    // we can check on the frontend to redirect to getting started if applicable
    const googleOauthSignInURL = routes.GOOGLE_OAUTH_LOGIN('google', targetPath);
    const omniOauthSignInURL = (provider) => routes.OMNI_OAUTH(provider);

    useEffect(() => {
        if (breakerTripped && !hasSeenBreakerError) {
            toast.error('Unable to log in via Google right now.  Please try again tomorrow.');
            setHasSeenBreakerError(true);
        }
    }, [breakerTripped, hasSeenBreakerError, setHasSeenBreakerError]);

    return (
        <div>
            <div className="pt-24">
                <img
                    className="mx-auto"
                    title="Sixty"
                    alt="Sixty"
                    src="/logo/mark.png"
                />
            </div>
            <Panel width="narrow">
                <Header align="left">
                    <div className="flex justify-center items-center">
                        <img src="/images/create-account.svg" alt="Create Sixty account" className="hidden sm:inline mr-[6px]" />
                        Welcome back!
                    </div>
                </Header>

                <div className="text-left text-cleanSlate text-[16px] font-normal leading-6 mt-4">
                    <b>Signing in Sixty is easy and fast!</b>
                    <br />
                    We&apos;ll send a magic link directly to your inbox.
                </div>

                <br />

                {accountDeleted && (
                    <i>
                        <div className="text-center">
                            Your account has been deleted. You can contact
                            {' '}
                            <a
                                className="linkLike--secondary"
                                href="mailto:support@sixty.inc"
                            >
                                support@sixty.inc
                            </a>
                            {' '}
                            within 7 days to restore it.  After that, it will be
                            permanently deleted, and all your data will be removed
                            from our systems.
                        </div>
                        <br />
                    </i>
                )}

                {useMagicLink && (
                    <div className="text-center">
                        <div className={styles.error}>
                            <div>
                                <ExclamationMarkSolid className="w-4 h-4 float-left mr-[2px] text-solidRed" />
                            </div>
                            <div className="pl-[25px]">
                                We&apos;re sorry, but you can&apos;t use that method to log in.
                                Please use a Magic Link instead.
                            </div>
                        </div>
                        <br />
                    </div>
                )}

                {missingCredentials && (
                    <div className="text-center">
                        <div className={styles.error}>
                            <div>
                                <ExclamationMarkSolid className="w-4 h-4 float-left mr-[2px] text-solidRed" />
                            </div>
                            <div className="pl-[25px]">
                                Something went wrong.  Please try again.
                                If you continue to see this error,
                                close this window, open a new one, and try again.
                            </div>
                        </div>
                        <br />
                    </div>
                )}

                <div>
                    <MagicLinkForm />
                    <div className="text-center text-cleanSlate text-[16px] font-normal leading-6 mt-0 mb-4">
                        <br />
                        You can also
                    </div>
                </div>

                <div className={styles.googleContainer}>
                    <h2 className={styles.googleContent}>
                        <a href={googleOauthSignInURL} aria-label="Log in with Google" className={onboardingStyles.internalLink}>
                            <SignInWithGoogle className={onboardingStyles.oauthButton} />
                        </a>
                    </h2>
                </div>

                {!IN_PROD && (
                    <div className={styles.googleContainer}>
                        <h2 className={styles.googleContent}>
                            <form method="post" action={omniOauthSignInURL('microsoft_graph')} data-turbo="false">
                                <input type="hidden" name="target" value={targetPath} />
                                <input type="hidden" name="mode" value="login" />
                                <button type="submit" aria-label="Log in with Microsoft" className={onboardingStyles.oauthButton}>
                                    <SignInWithMsft className={onboardingStyles.oauthButton} />
                                </button>
                            </form>
                        </h2>
                    </div>
                )}

                <div className="flex flex-col sm:flex-row items-center justify-center mt-4 ">
                    <div>
                        {' '}
                        <Link
                            to={routes.SIGN_UP}
                            className={styles.externalLinkPlain}
                        >
                            New to Sixty?
                            {' '}
                            <span className={styles.externalLinkLink}>Sign up.</span>
                        </Link>
                    </div>
                    <div className="px-2 hidden sm:inline"> &#124; </div>
                    <div>
                        {' '}
                        <Link to={routes.HELP} target="_blank" className={styles.externalLinkPlain}>
                            Having trouble?
                            {' '}
                            <span className={styles.externalLinkLink}>We can help. </span>
                        </Link>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

Login.getLayout = function getLayout(page) {
    return <ThinLayout>{page}</ThinLayout>;
};

export default Login;
