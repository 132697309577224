import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { LiveChatLoaderProvider, HelpScout } from 'react-live-chat-loader';

import { userAtom, serverDownErrorAtom } from '@/components/with_auth/State';
import NetworkError from '@/components/commons/network_error';

declare global {
    interface Window {
        Beacon: any
    }
    // eslint-disable-next-line vars-on-top,no-var
    var window: Window & typeof globalThis;
}

export default function ThinLayout({ children }) {
    const user = useRecoilValue(userAtom);
    const serverDownError = useRecoilValue(serverDownErrorAtom);
    const [beaconLoaded, setBeaconLoaded] = useState(false);

    useEffect(() => {
        if (!beaconLoaded || !user || !window || !window.Beacon) {
            return;
        }

        const createdAtRaw = user.createdAt;
        let createdAt;
        if (createdAtRaw) {
            [createdAt] = createdAtRaw.split('T');
        }

        // to track user / update their info
        window.Beacon('identify', {
            name: user.fullName,
            email: user.email,
            'customer-id': user.id,
            industry: user.industry,
            occupation: user.occupation,
            role: user.role,
            'timezone-name': user.timezoneName,
            'timezone-offset': user.timezoneOffset,
            'customer-since': createdAt,
        });
    }, [beaconLoaded, user]);

    return (
        <div>
            <div className="flex flex-row">
                <main className="p-5 md:pt-10 sixty-mountain w-full h-full min-h-[100vh] overflow-x-clip">
                    <div className="">
                        {serverDownError ? (
                            <NetworkError />
                        ) : children}
                    </div>
                </main>
            </div>
            <LiveChatLoaderProvider
                providerKey={process.env.NEXT_PUBLIC_HELPSCOUT_KEY}
                provider="helpScout"
                onReady={() => {
                    setBeaconLoaded(true);
                }}
            >
                <HelpScout icon="message" color="green" zIndex="999" horizontalPosition="right" />
            </LiveChatLoaderProvider>
        </div>
    );
}
